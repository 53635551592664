import moment from "moment";
import config from "./config";

const constant = {};

constant.SERVICEID = {
  APPLICATION_DETAIL: "64880fe3ab984c72ba592d8a",
};

constant.STEPID_PATH = {
  SE_Input_information: "/eSign_signing_login",
  SE_Review: "/eSign_view_contract",
  SE_Input_OTP: "/eSign_verify_es",
  SE_eKYC: "/eSign_start_confirm_infor",
  SE_Finish: "/eSign_view_contract",
  SE_Go_to_counter: "/eSign_view_contract",
};

constant.IDENTITY_TYPE = {
  CCCD: "Căn cước công dân",
  CMND: "Chứng minh nhân dân",
  CMQD: "Chứng minh Quân đội",
  PASSPORT: "Hộ chiếu"
};

constant.IDENTITY = {
  CCCD: "Số CCCD",
  CMND: "Số CMND",
  CMQD: "Số CM Quân đội",
  PASSPORT: "Hộ chiếu"
}

constant.STEP_NO = {
  SE_Input_information: 1,
  SE_Review: 2,
  SE_Input_OTP: 4,
  SE_eKYC: 3,
  SE_Finish: 5,
  SE_Go_to_counter: 3.5,
};

constant.TRUEID_CONFIG = {
  clientId: config.clientIdSDK,
  clientSecret: config.client_secret,
  configHeader: "{\"header1\":\"header1_value\", \"header2\":\"header2_value\"}",
  stepVerification: ["FRONT", "BACK", "SELFIE", "EDITOCR"],
  domain: config.baseApiSDK,
  configEndpoint: {
    front: "/ekyc/v1.2/id/verify/front",
    back: "/ekyc/v1.2/id/verify/back",
    selfie: "/ekyc/v1.2/selfie/verify",
    complete: "/ekyc/v1.2/complete",
    nfcqrverify: "/ekyc/v2.0/nfcqr/verify",
    nfcrar: "/ekyc/v1.2/nfcqr/upload",
    ocr: "/ekyc/v1.2/id/ocr",
    nfcbshield: "/ekyc/v1.2/nfcqr/upload",
    createrequest: "/ekyc/v1.2/request/create",
    accesstoken: "/auth/v1/oauth/accessToken"
  },
  titleColor: "#091E42CC",//80%
  subTitleColor: "#091E4299",//60%
  closeColor: "#091E42CC",//80%
  buttonCaptureColor: "#f04544",
  titleButtonCaptureColor: "#ffffff",
  backgroundColor: "#ffffff",
  requestId: "",
  accessToken: ""
}

constant.style = {
  LFVN_THEME: "#E7252B",
  DISABLED: "#D6D6D6"
}

constant.STEP_MAPPING_CONTENT = (step, ekycTimestamp) => ({
  SE_Go_to_counter: "Kết quả eKYC không hợp lệ. Quý khách vui lòng qua chi nhánh để hoàn thành thủ tục ký",
  SE_Input_OTP: `Quý khách đã thực hiện thành công quá trình xác thực thông tin vào ${moment(ekycTimestamp).format("HH:mm:ss")} ngày ${moment(ekycTimestamp).format("DD-MM-YYYY")}. Vui lòng không thoát khỏi app và tiếp tục tiến trình ký Hợp đồng điện tử`,
  SE_eKYC: "Kết quả eKYC không hợp lệ. Quý khách vui lòng thực hiện lại.",
  pending: "Hệ thống đang bận vui lòng thử lại",
}[step])


export default constant;
