export default {
  getLocation: (callbackSuccess, callbackFail) => {
    navigator?.geolocation.getCurrentPosition(
      (geo) => onSuccess(geo, callbackSuccess),
      (err) => onFail(err, callbackFail)
    );
  },

  requestCameraPermission: async () => {
    try {
      let permission = await navigator.permissions.query({ name: "camera" });
      return permission.state;
    } catch (err) {
      return "denied";
    }
  },
};

const onSuccess = (geo, callback) => {
  callback({
    lat: geo.coords.latitude,
    timestamp: geo.timestamp,
    lng: geo.coords.longitude,
  });
};

const onFail = (err, callback) => {
  console.warn(`ERROR(${err.code}): ${err.message}`);
  callback?.(err);
};
